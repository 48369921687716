#chatbot-loader #chatbot-loader-dot1 {
	animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
	animation: load 1s infinite;
	animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
	animation: load 1s infinite;
	animation-delay: 0.4s;
}

@keyframes load {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
